



















































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component, Prop, Watch } from "vue-property-decorator";

import api from "@/core/utils/api";
import VideoRecorder from "@/components/common/VideoRecorder.vue";
import VideoPlayer from "@/components/common/VideoPlayer.vue";
import {
  AddSubmissionAction,
  Seminar,
  SeminarElement,
  Submission,
} from "@/core/models";
import ElementControls from "./ElementControls.vue";
import VideoSubmission from "./VideoSubmission.vue";

type Video = {
  subId: number;
  userId: string;
  uri: string;
};

@Component({
  components: {
    VideoRecorder,
    VideoPlayer,
    ElementControls,
    VideoSubmission,
  },
})
export default class GiveFeedbackElement extends Vue {
  @Getter("seminars/selectedId") id!: number;
  @Prop({ default: () => undefined }) seminar?: Seminar;
  @Prop({ default: () => ({} as SeminarElement) }) element!: SeminarElement;
  @Action("seminars/addSubmission")
  addSubmission!: (d: AddSubmissionAction) => void;

  get submissions() {
    return (
      this.seminar?.submissions?.filter(
        x => x.elementId === this.element.id && !x.skipped,
      ) || []
    );
  }

  get skipped() {
    return this.seminar?.submissions?.find(
      x => x.skipped && x.elementId === this.element.id,
    );
  }

  get canShare() {
    if (this.element.type !== "give_feedback") return false;
    return this.element.rateableSubmissions || false;
  }

  loading = false;
  video: Video | null = null;
  async getVideo() {
    this.loading = true;
    try {
      const end = `/api/Elements/RandomVideoPresentation/${this.element.id}`;
      this.video = (await api.get(end)) || null;
      console.log(this.video);
    } catch (error) {
      console.log(error);
    }
    this.loading = false;
  }

  submitted = false;
  uploading = false;
  async upload({
    blob,
    askForFeedback,
  }: {
    blob: Blob;
    askForFeedback: boolean;
  }) {
    if (!this.video) throw new Error("no_video");

    this.uploading = true;
    try {
      const data = new FormData();
      data.append("elementId", this.element.id.toString());
      data.append("feedbackFor", this.video.userId);
      data.append("feedbackForSubmission", this.video.subId.toString());
      data.append("isShared", this.canShare.toString());
      data.append("videoFile", blob);
      data.append("seminarId", this.element.seminarId.toString());
      data.append("askForFeedback", askForFeedback.toString());
      const submission = (await api.post(
        "/api/Submissions/GiveFeedback",
        data,
        { headers: { "Content-Type": "multipart/form-data" } },
      )) as Submission;
      this.addSubmission({ submission, blockId: this.element.blockId });
    } catch (error) {
      console.log(error);
    }
    this.uploading = false;
  }

  @Watch("id", { immediate: true })
  seminarUpdated() {
    if (!this.id) return;
    this.getVideo();
  }
}
