




















































import Vue from "vue";
import { Action } from "vuex-class";
import { Component, Prop, Watch } from "vue-property-decorator";

import api from "@/core/utils/api";
import {
  AddSubmissionAction,
  Seminar,
  SeminarElement,
  Submission,
} from "@/core/models";

import GapTextSubmission from "./GapTextSubmission.vue";
import ElementControls from "./ElementControls.vue";

@Component({ components: { GapTextSubmission, ElementControls } })
export default class GapTextElement extends Vue {
  @Prop({ default: () => undefined }) seminar?: Seminar;
  @Prop({ default: () => ({} as SeminarElement) }) element!: SeminarElement;
  @Action("seminars/addSubmission")
  addSubmission!: (d: AddSubmissionAction) => void;
  @Action("displaySnackbar") displaySnackbar!: (m: string) => void;

  inputs: string[][] = [];
  get parts() {
    if (this.element.type !== "gap_text") return [];
    return this.element.text.split("\n").map(x => x.split("__"));
  }
  @Watch("element", { immediate: true })
  elementChanged() {
    if (this.element.type !== "gap_text") return;
    this.parts.forEach(x => {
      const gapCount = x
        .map(x => x.split("__").length)
        .reduce((cum, cur) => cum + cur);
      this.inputs.push(new Array(gapCount - 1).fill(""));
    });
  }

  get submissions() {
    return (
      this.seminar?.submissions?.filter(
        x => x.elementId === this.element.id && !x.skipped,
      ) || []
    );
  }

  get skipped() {
    return this.seminar?.submissions?.find(
      x => x.skipped && x.elementId === this.element.id,
    );
  }

  submitting = false;
  async submit() {
    if (this.element.type !== "gap_text") throw new Error("invalid");

    if (this.inputs.some(x => x.some(y => y.trim().length === 0))) {
      this.displaySnackbar(this.$t("seminars.gap_text.error").toString());
      throw new Error("invalid");
    }

    this.submitting = true;
    try {
      const data = {
        elementId: this.element.id,
        answers: this.inputs.flat(),
        seminarId: this.element.seminarId,
      };
      const submission = (await api.post(
        "/api/Submissions/GapText",
        data,
      )) as Submission;
      this.addSubmission({ submission, blockId: this.element.blockId });
    } catch (error) {
      this.displaySnackbar(error.description);
    }
    this.submitting = false;
  }
}
