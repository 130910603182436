



























































































import Vue from "vue";
import { Action } from "vuex-class";
import { Component, Prop, Watch } from "vue-property-decorator";

import api from "@/core/utils/api";
import {
  AddSubmissionAction,
  Seminar,
  SeminarElement,
  Submission,
} from "@/core/models";
import CategorizeSubmission from "./CategorizeSubmission.vue";
import ElementControls from "./ElementControls.vue";

@Component({ components: { CategorizeSubmission, ElementControls } })
export default class CategorizeElement extends Vue {
  @Prop({ default: () => undefined }) seminar?: Seminar;
  @Prop({ default: () => ({} as SeminarElement) }) element!: SeminarElement;
  @Action("seminars/addSubmission")
  addSubmission!: (d: AddSubmissionAction) => void;
  @Action("displaySnackbar") displaySnackbar!: (m: string) => void;

  get submissions() {
    return (
      this.seminar?.submissions?.filter(
        x => x.elementId === this.element.id && !x.skipped,
      ) || []
    );
  }

  get skipped() {
    return this.seminar?.submissions?.find(
      x => x.skipped && x.elementId === this.element.id,
    );
  }

  get randomPhrases() {
    return this.phrases.sort(function () {
      return 0.5 - Math.random();
    });
  }

  justSubmitted = false;
  input: string[][] = [];
  submitting = false;
  phrases: string[] = [];

  async submit() {
    if (this.submitting || this.justSubmitted) throw new Error("submitting");

    if (this.phrases.length > 0) {
      this.displaySnackbar(this.$t("seminars.categorize.error").toString());
      throw new Error("invalid");
    }

    this.submitting = true;
    try {
      const data = {
        elementId: this.element.id,
        answers: this.input,
        seminarId: this.element.seminarId,
      };
      const submission = (await api.post(
        "/api/Submissions/Categorize",
        data,
      )) as Submission;
      this.addSubmission({ submission, blockId: this.element.blockId });
      this.justSubmitted = true;
    } catch (error) {
      this.displaySnackbar(error.description);
    }
    this.submitting = false;
  }

  removePhrase(i: number, j: number) {
    const phrase = this.input[i][j];
    this.input[i] = this.input[i].filter(x => x !== phrase);
    this.phrases = [...this.phrases, phrase];
  }

  isCorrect(i: number, j: number) {
    if (this.element.type !== "categorize") return;
    const phrase = this.input[i][j].toLowerCase();
    return this.element.answers[i].map(x => x.toLowerCase()).includes(phrase);
  }

  correctCategory(i: number, j: number) {
    if (this.element.type !== "categorize") return "<<unknown>>";
    const phrase = this.input[i][j];
    for (let ci = 0; ci < this.element.answers.length; ci++)
      if (this.element.answers[ci].some(x => x === phrase))
        return this.element.categories[ci];
    return "<<unknown>>";
  }

  dragged: string | null = null;
  created() {
    if (this.element.type !== "categorize") return;
    this.input = new Array(this.element.categories.length).fill([]);
    this.phrases = this.element.words || [];

    document.addEventListener("dragover", ev => {
      ev.preventDefault();
    });
    document.addEventListener("dragstart", ev => {
      if (!ev.target) return;
      const idx = Number((ev.target as HTMLDivElement).dataset.phrase);
      if (isNaN(idx)) return;
      this.dragged = this.phrases[idx];
    });
    document.addEventListener("dragenter", ev => {
      // @ts-ignore
      if (!ev.target?.classList?.contains("dropzone")) return;
      const idx = Number((ev.target as HTMLDivElement).dataset.category);
      if (isNaN(idx)) return;
    });
    document.addEventListener("dragleave", ev => {
      // @ts-ignore
      if (!ev.target?.classList?.contains("dropzone")) return;
      const idx = Number((ev.target as HTMLDivElement).dataset.category);
      if (isNaN(idx)) return;
    });
    document.addEventListener("drop", ev => {
      ev.preventDefault();
      // @ts-ignore
      if (!ev.target?.classList?.contains("dropzone")) return;
      const idx = Number((ev.target as HTMLDivElement).dataset.category);
      if (!this.dragged || isNaN(idx)) return;
      this.phrases = this.phrases.filter(x => x !== this.dragged);
      this.input[idx] = [...this.input[idx], this.dragged];
    });
  }

  @Watch("element")
  elementChanged() {
    this.justSubmitted = false;
  }
}
