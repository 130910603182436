



























































import Vue from "vue";
import { Action } from "vuex-class";
import { Component, Prop } from "vue-property-decorator";

import api from "@/core/utils/api";
import {
  AddSubmissionAction,
  Seminar,
  SeminarElement,
  Submission,
} from "@/core/models";

import VideoRecorder from "@/components/common/VideoRecorder.vue";
import VideoPlayer from "@/components/common/VideoPlayer.vue";
import SubmissionFooter from "./SubmissionFooter.vue";
import ElementControls from "./ElementControls.vue";
import VideoSubmission from "./VideoSubmission.vue";

@Component({
  components: {
    VideoRecorder,
    SubmissionFooter,
    VideoPlayer,
    ElementControls,
    VideoSubmission,
  },
})
export default class RecordVideoElement extends Vue {
  @Prop({ default: () => ({} as Seminar) }) seminar!: Seminar;
  @Prop({ default: () => ({} as SeminarElement) }) element!: SeminarElement;
  @Action("seminars/addSubmission")
  addSubmission!: (d: AddSubmissionAction) => void;

  get submissions() {
    return (
      this.seminar.submissions?.filter(
        x => x.elementId === this.element.id && !x.skipped,
      ) || []
    );
  }

  get sharedSubs() {
    return (
      this.seminar.submissions?.filter(
        x => x.elementId === this.element.id && !x.skipped && x.isShared,
      ) || []
    );
  }

  get skipped() {
    return this.seminar?.submissions?.find(
      x => x.skipped && x.elementId === this.element.id,
    );
  }

  get numReq() {
    if (this.element.type !== "video_presentation") return 1;
    return this.element.requiredSubmissions || 1;
  }

  get numReqShared() {
    if (this.element.type !== "video_presentation") return 1;
    return this.element.requiredNumShared || 1;
  }

  get maxNumSubs() {
    if (this.element.type !== "video_presentation") return 1;
    return this.element.maxSubmissions || 1;
  }

  get maxNumShared() {
    if (this.element.type !== "video_presentation") return 1;
    return this.element.maxNumShared || 1;
  }

  get canRecord() {
    if (this.element.type !== "video_presentation") return false;
    return this.submissions.length < this.maxNumSubs;
  }

  get canShare() {
    if (this.element.type !== "video_presentation") return false;
    const maxShared = this.element.maxNumShared || 0;
    return maxShared !== 0 && this.sharedSubs.length < maxShared;
  }

  submitting = false;
  async upload({
    blob,
    isShared,
    askForFeedback,
  }: {
    blob: Blob;
    isShared: boolean;
    askForFeedback: boolean;
  }) {
    this.submitting = true;
    try {
      const data = new FormData();
      const file = new File([blob], "record_video.mp4", { type: "video/mp4" });
      data.append("elementId", this.element.id.toString());
      data.append("isShared", isShared.toString());
      data.append("seminarId", this.seminar.id.toString());
      data.append("askForFeedback", askForFeedback.toString());
      data.append("videoFile", file);
      const submission = (await api.post(
        "/api/Submissions/VideoPresentation",
        data,
        { headers: { "Content-Type": "multipart/form-data" } },
      )) as Submission;
      submission.isShared = isShared;
      this.addSubmission({
        submission,
        blockId: this.element.blockId,
      });
    } catch (error) {
      console.log(error);
    }
    this.submitting = false;
  }
}
