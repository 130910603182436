












































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component, Prop } from "vue-property-decorator";

import api from "@/core/utils/api";
import { Seminar, SeminarElement } from "@/core/models";

const emptySubmissionElements = new Map([
  ["read_text", "/api/Submissions/ReadText"],
  ["watch_video", "/api/Submissions/WatchVideo"],
]);

@Component
export default class ElementControls extends Vue {
  @Prop({ default: () => false }) isLast!: boolean;
  @Prop({ default: () => {} }) submit!: () => Promise<void>;
  @Getter("seminars/selected") selected?: Seminar;
  @Getter("seminars/element") element!: SeminarElement;
  @Action("seminars/next") next!: () => void;
  @Action("seminars/prev") prev!: () => void;
  @Action("seminars/markComplete") complete!: (s: boolean) => void;
  @Action("displaySnackbar") displaySnackbar!: (m: string) => void;

  loading = false;
  endDialog = false;
  async callNext() {
    // no element
    if (!this.element) return this.displaySnackbar("Element not found");

    // element completed
    if (this.element.completed) {
      if (this.isLast) this.endDialog = true;
      return this.next();
    }

    // specific to vr_training
    if (this.element.type === "vr_training") {
      const reqScore = this.element.requiredScore;
      const bestScore =
        this.selected?.submissions
          ?.filter(x => x.elementId === this.element.id)
          .map(x => (x as any).score as number)
          .sort((a, b) => b - a)[0] || 0;
      if (bestScore < reqScore)
        return this.displaySnackbar(
          "You need to reach the required score in order to proceed",
        );
      else {
        this.complete(false);
        return this.next();
      }
    }

    // specific to give_feedback
    if (this.element.type === "give_feedback") {
      const hasSub = this.selected?.submissions?.some(
        x => x.elementId === this.element.id && !x.skipped,
      );
      if (hasSub) {
        this.complete(false);
        return this.next();
      }

      const msg =
        "You need to give feedback to another user. If no video is shown, feel free to skip the task";
      return this.displaySnackbar(msg);
    }

    // specific to video_presentations
    if (this.element.type === "video_presentation") {
      const subs = this.selected?.submissions?.filter(
        x => x.elementId === this.element.id && !x.skipped,
      );
      const numReq = this.element.requiredSubmissions || 1;
      const numSub = subs?.length || 0;
      const numReqShared = this.element.requiredNumShared || 0;
      const numShared = subs?.filter(x => x.isShared).length || 0;

      // requirements not met
      if (numReq > numSub || numReqShared > numShared) {
        const submissions = numReq - numSub;
        const sharedSubmissions = numReqShared - numShared;
        const m = this.$t("seminars.video_presentation.error", {
          submissions,
          sharedSubmissions,
        }).toString();
        this.displaySnackbar(m);
        return;
      }
      // req. met
      else {
        if (this.isLast) this.endDialog = true;
        this.complete(false);
        return this.next();
      }
    }

    // elements that require empty submissions
    if (emptySubmissionElements.has(this.element.type)) {
      api.post(emptySubmissionElements.get(this.element.type) as string, {
        elementId: this.element.id,
        seminarId: this.element.seminarId,
      });
      this.complete(false);
      this.next();
    }
    // elements that need to be submitted
    else {
      this.loading = true;
      try {
        await this.submit();
        this.complete(false);
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    }

    // if is last - show dialog
    if (this.isLast) this.endDialog = true;
  }

  toDash() {
    const id = this.selected?.id;
    if (!id) return this.displaySnackbar("Could not go back to dashboard");
    const route = `/seminars/dashboard/${id}`;
    this.$router.push(route);
  }
}
