




















import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component, Prop, Watch } from "vue-property-decorator";

import { Seminar, SeminarElement, SimpleUser, Submission } from "@/core/models";
import api from "@/core/utils/api";
import VideoSubmission from "./VideoSubmission.vue";
import ElementControls from "./ElementControls.vue";

@Component({ components: { VideoSubmission, ElementControls } })
export default class WatchFeedbackElement extends Vue {
  @Getter("seminars/users") users!: SimpleUser[];
  @Getter("seminars/selected") seminar?: Seminar;
  @Prop({ default: () => ({} as SeminarElement) }) element!: SeminarElement;
  @Action("seminars/next") next!: () => void;
  @Action("seminars/markComplete") complete!: (s: boolean) => void;

  user(id: string) {
    const one = this.users.find(x => x.id === id);
    if (!one) return "Seminar owner";
    return `${one.username} (${one.email})`;
  }

  videos: Submission[] = [];
  loading = false;
  async getVideos() {
    if (!this.seminar) return;
    this.loading = true;
    try {
      this.videos = (await api.get(
        `/api/Elements/Feedback/${this.seminar.id}`,
      )) as Submission[];
    } catch (error) {
      console.log(error);
    }
    this.loading = false;
  }

  submit() {
    if (!this.videos.length) throw new Error("no_video");
    api.post("/api/Submissions/WatchFeedback", {
      elementId: this.element.id,
      seminarId: this.element.seminarId,
    });
    this.complete(false);
    this.next();
  }

  @Watch("seminar", { immediate: true })
  seminarUpdated() {
    this.getVideos();
  }
}
