




















































































import Vue from "vue";
import { Getter } from "vuex-class";
import { Component, Watch } from "vue-property-decorator";
import { Seminar, SeminarBlock, SeminarElement } from "@/core/models";

import WatchVideo from "./WatchVideo.vue";
import RecordVideo from "./RecordVideo.vue";
import GiveFeedback from "./GiveFeedback.vue";
import WatchFeedback from "./WatchFeedback.vue";
import GapText from "./GapText.vue";
import Rephrase from "./Rephrase.vue";
import Categorize from "./Categorize.vue";
import VRTraining from "./VRTraining.vue";
import ElementControls from "./ElementControls.vue";

@Component({
  components: {
    WatchVideo,
    RecordVideo,
    GiveFeedback,
    WatchFeedback,
    GapText,
    Rephrase,
    Categorize,
    VRTraining,
    ElementControls,
  },
})
export default class SeminarContent extends Vue {
  @Getter("profile/getUserEmail") myEmail!: string;
  @Getter("seminars/selected") seminar?: Seminar;
  @Getter("seminars/block") block?: SeminarBlock;
  @Getter("seminars/element") element?: SeminarElement;

  // get canSkip() {
  //   return (
  //     ["read_text", "watch_video"].includes(this.element?.type || "") ||
  //     (!this.element?.completed && !this.element?.skipped)
  //   );
  // }

  percent = 0;
  calcPercent() {
    if (!this.block) return;
    const complete = this.block.elements.filter(x => x.completed).length;
    const val = Math.round((complete / this.block.elements.length) * 100);
    this.percent = isNaN(val) ? 0 : val;
  }

  @Watch("seminar", { immediate: true })
  seminarChanged() {
    this.calcPercent();
  }
  @Watch("block", { immediate: true })
  blockChanged() {
    this.calcPercent();
  }

  openEdit() {
    if (!this.seminar || !this.block || !this.element) return;
    const route = `/seminars/edit/${this.seminar.id}?block=${this.block.id}&element=${this.element.id}`;
    this.$router.push(route);
  }

  get isLast() {
    const idx = this.seminar?.elements?.findIndex(
      x => x.id === this.element?.id,
    );
    return (
      idx === (this.seminar?.elements?.length || 0) - 1 &&
      this.element?.type === "read_text"
    );
  }
  get shouldShowControls() {
    return (
      this.element?.type === "read_text" ||
      this.element?.type === "watch_video" ||
      this.element?.type === "vr_training"
    );
  }
}
