

























import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component } from "vue-property-decorator";

import { Seminar, SeminarElement } from "@/core/models";
import Sidebar from "../components/Sidebar.vue";
import Content from "../components/Content.vue";

@Component({ components: { Sidebar, Content } })
export default class SeminarDetails extends Vue {
  @Getter("seminars/loading") loading!: boolean;
  @Getter("seminars/selected") seminar?: Seminar;
  @Getter("seminars/element") element?: SeminarElement;
  @Getter("seminars/selectableUpTo") selectableUpTo!: [number, number];

  @Action("seminars/next") next!: () => void;
  @Action("seminars/get") getSeminar!: (id: number) => Promise<void>;
  @Action("seminars/markComplete") markComplete!: (s: boolean) => Promise<void>;

  @Action("displaySnackbar") displaySnackbar!: (m: string) => void;

  async complete(skip = true) {
    if (this.element?.completed || this.element?.skipped) return this.next();

    try {
      await this.markComplete(skip);
      this.next();
    } catch (error) {
      console.log(error);
      this.displaySnackbar("Failed to skip element");
    }
  }

  created() {
    const param = this.$route.params.id as string | undefined;
    if (!param) this.$router.replace("/seminars?from=details");
    else this.getSeminar(Number(param));
  }
}
